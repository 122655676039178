<template>
  <div class="components-form-advanced-search">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='名称'>
            <a-input placeholder="Name" v-model="searchForm.Name"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='手机号'>
            <a-input placeholder="Phone" v-model="searchForm.Phone"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='状态'>
            <a-select v-model="searchForm.Status">
              <a-select-option value="1">
                启用
              </a-select-option>
              <a-select-option value="0">
                待关联机构
              </a-select-option>
              <a-select-option value="-100">
                审核失败
              </a-select-option>
              <a-select-option value="-200">
                冻结
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='业务识别代码'>
            <a-input placeholder="业务识别代码" v-model="searchForm.BusinessCode"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          :style="{ display: expand ? 'block' : 'none' }"
        >
          <a-form-item label='注册时间'>
            <a-range-picker v-model="searchForm.CreatedAt" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick">
            查询
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="expand = !expand">
            {{expand ? '收起' : '展开'}} <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    
    
    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      
      <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-col style="display: flex;">
          <a-button
            icon="plus"
            style="
              background-color: #87d068;
              color: #fff;
              border-color: #87d068;
            "
            @click="addFormShowHandle"
            v-if="
              $root.$data.privateState.action.includes(
                '/Customer/CreateUser'
              ) ||
              ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)
            "
            >新建</a-button
          >
          <a-divider type="vertical" style="height:100%" v-if="$root.$data.privateState.action.includes('/Customer/ExportScoreFlow') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)" />
          <a-col>
            <a-button  icon="cloud-upload" type="danger" @click="exportScoreFlow" v-if="($root.$data.privateState.action.includes('/Customer/ExportScoreFlow') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role))">导出统计报表</a-button>
          </a-col>
          <a-divider type="vertical" />
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="column-height" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'default' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'default'">默认</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'middle' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'middle'">中等</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'small' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'small'">紧凑</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="() => {screenfull.toggle();isFullscreen=!isFullscreen}"/>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" icon="reload" @click="searchClick" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group :value="showColumns" @change="changeColumns">
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{it}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="setting" />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        :columns="columns"
        :rowKey="record => record.uuid"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 1300 }"
        @change="handleTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #status="status">
          <a-tag :color="customerStatusDic[status].color">{{customerStatusDic[status].label}}</a-tag>
        </template>
        <template #createdAt="createdAt">
          {{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template #action="data">
          <a-button v-if="$root.$data.privateState.role === 'SuperAdmin' || $root.$data.privateState.action.includes('/Customer/EditUser')" type="link" @click="editUser(data)">编辑</a-button>
          <a-button v-if="$root.$data.privateState.role === 'SuperAdmin' || $root.$data.privateState.action.includes('/Customer/ScoreChange')" type="link" @click="scoreChange(data)">调整积分</a-button>
          <a-popover title="积分日志" >
            <template slot="content">
              <a-row :gutter="[8, 24]">
                <a-col><a-button type="link" @click="showScoreLogs(data, 'Regular')">积分日志</a-button></a-col>
                <a-col><a-button type="link" @click="showScoreLogs(data, 'Frozen')">预估扣除积分日志</a-button></a-col>
              </a-row>
            </template>
            <a-button type="link">积分日志</a-button>
        </a-popover>
          
        </template>
      </a-table>
    </div>
    <!-- table end -->
    <a-modal
      title="积分调整"
      v-model="changeScoreShow"
      @ok="changeScoreOk"
      :keyboard="false"
      :maskClosable="false"
      width="60%"
    >
      <a-form-model ref="changeScoreForm" :model="changeScoreForm" :rules="changeScoreFormRules" v-viewer="{movable: false}" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="增加或减少积分值" prop="score">
          <a-input-number style="width:100%" :min="0" v-model="changeScoreForm.score" />
        </a-form-model-item>
        <a-form-model-item label="调整类型" prop="customerScoreChangeType">
          <a-select v-model="changeScoreForm.customerScoreChangeType" >
            <a-select-option
              v-for="it in [
                { title: '增加', value: '0' },
                { title: '减少', value: '1' },
              ]"
              :key="it.value"
              >{{ it.title }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="变更原因" prop="remarks">
          <a-textarea v-model="changeScoreForm.remarks" :auto-size="{ minRows: 2, maxRows: 3 }"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      title="设置用户"
      v-model="setCustomerShow"
      @ok="postSetCustomerOk"
      :keyboard="false"
      :maskClosable="false"
      width="60%"
    >
      <a-form-model class="setCustomerShow" ref="customerForm" :model="customerForm" :rules="customerFormRules" v-viewer="{movable: false}" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="名称" prop="name">
          <a-input v-model="customerForm.name" />
        </a-form-model-item>
        <a-form-model-item label="业务识别代码" prop="businessCode">
          <a-input v-model="customerForm.businessCode" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input v-model="customerForm.phone" />
        </a-form-model-item>
        <a-form-model-item v-if="formMode === 'edit'" label="归属机构" prop="belong">
          <a-tree-select
            placeholder="请选择"
            show-search
            v-model="customerForm.belong"
            :tree-data="organizationTree"
          />
        </a-form-model-item>
        <a-form-model-item label="用户名" prop="username">
          <a-input v-model="customerForm.username" />
        </a-form-model-item>
        <a-form-model-item label="密码" prop="password">
          <a-input v-model="customerForm.password" />
        </a-form-model-item>
        <a-form-model-item label="是否有业务员" prop="hasStaff">
          <a-switch checked-children="有" un-checked-children="无" v-model="customerForm.hasStaff"/>
        </a-form-model-item>
        <div v-if="customerForm.hasStaff">
          <a-form-model-item label="业务员姓名" prop="staffName">
            <a-input v-model="customerForm.staffName" />
          </a-form-model-item>
          <a-form-model-item label="业务员手机" prop="staffPhone">
            <a-input v-model="customerForm.staffPhone" />
          </a-form-model-item>
        </div>
        <a-form-model-item label="备注" prop="remarks">
          <a-input v-model="customerForm.remarks" type="textarea" />
        </a-form-model-item>
        <a-row :gutter="[16,16]">
          <a-col span="12">
            <a-divider>对公账户</a-divider>
            <div>
              <a-form-model-item label="开户行">
                <a-input v-model="customerForm.bankInfo.company.bankAccount" />
              </a-form-model-item>
              <a-form-model-item label="银行账户">
                <a-input v-model="customerForm.bankInfo.company.bankName" />
              </a-form-model-item>
              <a-form-model-item label="银行卡号">
                <a-input v-model="customerForm.bankInfo.company.bankCard" />
              </a-form-model-item>
            </div>
          </a-col>
          <a-col span="12">
            <a-divider>对私账户</a-divider>
            <div>
              <a-form-model-item label="开户行">
                <a-input v-model="customerForm.bankInfo.self.bankAccount" />
              </a-form-model-item>
              <a-form-model-item label="银行账户">
                <a-input v-model="customerForm.bankInfo.self.bankName" />
              </a-form-model-item>
              <a-form-model-item label="银行卡号">
                <a-input v-model="customerForm.bankInfo.self.bankCard" />
              </a-form-model-item>
            </div>
          </a-col>
        </a-row>
        <a-divider>提点比例设置</a-divider>
        <a-alert message="未设置提点比例，则提点比例统一默认为10%，如为设置积分提点比例，则积分提点比例默认与服务费提点比例相同！" banner />
        <a-row :gutter="[16,16]">
          <a-col span="12">
            <a-form-model-item label="服务费提点比例">
              <a-input-number
                v-model="customerForm.cutPercent"
                :min="0"
                :max="100"
                :formatter="value => `${value}%`"
                :parser="value => value.replace('%', '')"
              />
            </a-form-model-item>
          </a-col>
          <a-col span="12">
            <a-form-model-item label="积分提点比例">
              <a-input-number
                v-model="customerForm.cutPercentByScore"
                :min="0"
                :max="100"
                :formatter="value => `${value}%`"
                :parser="value => value.replace('%', '')"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <a-modal
      width="60%"
      v-model="logDataShow"
      title="额度日志"
      @ok="logDataShow = false"
    >
      <a-table
        :columns="logColumns"
        :rowKey="record => record.uuid"
        :dataSource="logData"
        :pagination="logPagination"
        :loading="logLoading"
        @change="handleLogTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #createdAt="createdAt">
          {{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
      </a-table>
    </a-modal>
    <!-- 导出报表 -->
    <a-modal
      v-model="showExportExcelDateSelect"
      title="导出报表"
      @ok="exportExcel"
      @cancel="showExportExcelDateSelect = false"

    >
      <a-row>
        <a-range-picker style="width:100%" :disabled-date="c => c > moment().endOf('day')" v-model="exportExcelDate" />
      </a-row>
    </a-modal>
    <a-modal
      :width="200"
      :visible="modalLoading"
      :closable="false"
      :maskClosable="false"
      :footer="null"
    >
      <a-row>
        <a-col style="text-align: center;">
          <a-icon type="loading" :style="{ fontSize: '36px', color: '#1890ff' }" />
        </a-col>
        <a-col style="text-align: center;color:#1890ff;padding-top:10px;">
          {{modalLoadingMessage}}
        </a-col>
      </a-row>
      
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import moment from 'moment'
import DownloadJS from "downloadjs";
import screenfull from 'screenfull'

import { Customer, Organization } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'

const INIT_SEARCH_FORM = '{"Name":"","Phone":"","Status":"","Type":"","CreatedAt":[],"BusinessCode":""}'
const columns = [
  {
    title: '业务识别代码',
    width: 150,
    dataIndex: 'businessCode',
  },
  {
    title: '名称',
    width: 150,
    dataIndex: 'name',
  },
  {
    title: '手机号',
    width: 150,
    dataIndex: 'phone',
  },
  {
    title: '积分',
    width: 120,
    dataIndex: 'score',
  },
  {
    title: '预估扣除积分',
    width: 120,
    dataIndex: 'frozenScore',
  },
  {
    title: '状态',
    width: 80,
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '归属机构',
    width: 150,
    dataIndex: 'belongOrganizationName',
  },
  {
    title: '注册时间',
    dataIndex: 'createdAt',
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: '操作',
    key: 'operation',
    width: 130,
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  }
]
export default {
  name: 'CustomerTableList',
  mixins: [aliveVM, mixinTableList],
  created () {
    const baseColumns = []
    for (const it of columns) {
      baseColumns.push(it.title)
    }
    this.$set(this, 'baseColumns', baseColumns)
    this.fetch()
  },
  async mounted () {
    const orgRes = await this.$axios.get(`${Organization}/GetTree`)
      .catch(e => {
        return {
          status: false,
          message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
        }
      })
    if (!orgRes.status) {
      this.$message.error(`获取组织树：${orgRes.message}`)
      this.noOrganizationTree = true
    } else {
      this.$set(this, 'organizationTree', orgRes.data)
    }
  },
  computed: {
    showColumns() {
      let c = []
      for (const it of this.columns) {
        c.push(it.title)
      }
      return c
    }
  },
  data () {
    return {
      modalLoading: false,
      modalLoadingMessage: '数据量较大，正在处理数据...',
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      screenfull,
      isFullscreen: false,
      expand: false,
      tableListApi: `${Customer}/TableList`,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      baseColumns: [],
      columns,
      // 其他
      moment,
      customerTypeDic: {
        '0': {
            label: '个人用户',
            color: '#2196F3',
        },
        '1': {
            label: '个人用户',
            color: '#2196F3',
          },
        '2': {
          label: '企业用户',
          color: '#9C27B0',
        }
      },
      customerRoleDic: {
        '0': {
          label: '个人用户',
          color: '#03A9F4',
        },
        '1': {
          label: '市场开发员',
          color: '#8BC34A',
        },
        '2': {
          label: '市场销售员',
          color: '#CDDC39',
        },
        '3': {
          label: '个人用户',
          color: '#03A9F4',
        }
      },
      customerStatusDic: {
        '-200': {
          label: '冻结',
          color: '#FF5722',
        },
        '-100': {
          label: '审核失败',
          color: '#FF9800',
        },
        '0': {
          label: '待关联机构',
          color: '#9E9E9E',
        },
        '100': {
          label: '启用',
          color: '#4CAF50',
        },
      },
      reviewCollapseKey: ['1'],
      taxpayerTypeList: ['小规模纳税人', '一般纳税人'],
      invoiceModeList: ['详细', '汇总'],
      insuranceBaseList: [],
      nowSelectedProduct: null,
      editUuid: '',
      setCustomerShow: false,
      nowReviewCustomer: null,
      formMode: '',
      customerForm: {
        name: '',
        businessCode: '',
        phone: '',
        username: '',
        password: '',
        belong: '',
        remarks: '',
        hasStaff: false,
        staffName: '',
        staffPhone: '',
        cutPercent: null,
        cutPercentByScore: null,
        bankInfo: {
          company: {
            bankName: '',
            bankAccount: '',
            bankCard: ''
          },
          self: {
            bankName: '',
            bankAccount: '',
            bankCard: ''
          }
        }
      },
      organizationTree: [],
      customerFormRules: {
        belong: [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      },
      // 积分变更
      changeScoreShow: false,
      changeScoreForm: {
        score: 0,
        customerScoreChangeType: '',
        remarks: ''
      },
      changeScoreFormRules: {
        score: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        customerScoreChangeType: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        remarks: [
          { required: true, message: '请输入', trigger: 'blur' },
        ]
      },
      // 额度日志
      logColumns: [
        {
          title: '之前余额',
          dataIndex: 'beforeBalance',
        },
        {
          title: '增加',
          dataIndex: 'add'
        },
        {
          title: '减少',
          dataIndex: 'reduce'
        },
        {
          title: '之后余额',
          dataIndex: 'balance',
        },
        {
          title: '备注',
          dataIndex: 'remarks',
        },
        {
          title: '时间',
          dataIndex: 'createdAt',
          scopedSlots: { customRender: 'createdAt' },
        },
      ],
      logDataShow: false,
      nowLogUuid: '',
      logData: [],
      logPagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        filters: null,
        sorter: null
      },
      logLoading: false,
      logType: '',
      // qr
      showQrCodeModal: false,
      qrUrl: '',
      // 配件导出
      exportExcelDate: [],
      showExportExcelDateSelect: false
    }
  },
  methods: {
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    async reload() {
      this.$set(this, 'searchParams', null)
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', null)
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      this.pagination.current = 1
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      const searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (k === 'Role' && it) {
          searchParams[k] = parseInt(it)
          continue
        }
        if (k === 'Belong' && it) {
          searchParams[k] = it.key
          continue
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    // other
    scoreChange(data) {
      this.editUuid = data.uuid
      this.$set(this, 'changeScoreForm', {
        score: 0,
        customerScoreChangeType: '',
        remarks: ''
      })
      this.changeScoreShow = true
    },
    async changeScoreOk() {
      this.$refs.changeScoreForm.validate(async valid => {
        if (!valid) {
          this.$message.error('请检查填写内容');
          return
        }
        const postData = JSON.parse(JSON.stringify(this.changeScoreForm))
        const res = await this.$axios.post(`${Customer}/ScoreChange`, {
          customerUuid: this.editUuid,
          ...postData
        })
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
        this.setCustomerShow = false
        if (!res.status) {
          this.$message.error(res.message)
          return
        }
        this.$message.success("积分变更成功")
        this.changeScoreShow = false
        this.fetch()
      })
    },
    addFormShowHandle() {
      this.formMode = 'create'
      const customerForm = {
        name: '',
        businessCode: '',
        phone: '',
        username: '',
        password: '',
        belong: '',
        remarks: '',
        hasStaff: false,
        staffName: '',
        staffPhone: '',
        cutPercent: null,
        cutPercentByScore: null,
        bankInfo: {
          company: {
            bankName: '',
            bankAccount: '',
            bankCard: ''
          },
          self: {
            bankName: '',
            bankAccount: '',
            bankCard: ''
          }
        }
      };
      this.$set(this, 'customerForm', customerForm)
      this.setCustomerShow = true
    },
    postSetCustomerOk () {
      this.$refs.customerForm.validate(async valid => {
        if (!valid) {
          this.$message.error('请检查填写内容');
          return
        }
        const postData = JSON.parse(JSON.stringify(this.customerForm))
        let url = ''
        if (this.formMode === 'create') {
          url = `${Customer}/CreateUser`
        } else if (this.formMode === 'edit') {
          url = `${Customer}/EditUser?uuid=${this.editUuid}`
        } else {
          this.$message.error('表单类型错误，请重试');
          return
        }
        const res = await this.$axios.post(url, postData)
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
        
        if (!res.status) {
          this.$message.error(res.message)
          return
        }
        this.$message.success("操作成功！")
        this.setCustomerShow = false
        this.fetch()
      })
    },
    async editUser (data) {
      const customerModelRes = await this.$axios.get(`${Customer}/DetailBySystemUser?uuid=${data.uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!customerModelRes.status) {
        this.$message.error(customerModelRes.message)
        return
      }
      this.nowReviewCustomer = customerModelRes.data
      this.nowSelectedProduct = null
      const customerForm = {
        name: customerModelRes.data.name,
        businessCode: customerModelRes.data.businessCode ? customerModelRes.data.businessCode : '',
        username: customerModelRes.data.username,
        phone: customerModelRes.data.phone,
        hasStaff: customerModelRes.data.hasStaff ? true : false,
        staffName: '',
        staffPhone: '',
        belong: customerModelRes.data.belong ? customerModelRes.data.belong : '',
        remarks: customerModelRes.data.remarks,
        cutPercent: customerModelRes.data.cutPercent,
        cutPercentByScore: customerModelRes.data.cutPercentByScore,
        password: '',
        bankInfo: {
          company: {
            bankName: '',
            bankAccount: '',
            bankCard: ''
          },
          self: {
            bankName: '',
            bankAccount: '',
            bankCard: ''
          }
        }
      };
      if (customerModelRes.data.hasStaff) {
        customerForm.staffName = customerModelRes.data.staffName
        customerForm.staffPhone = customerModelRes.data.staffPhone
      }
      if (customerModelRes.data.bankInfo) {
        if (customerModelRes.data.bankInfo.company) {
          customerForm.bankInfo.company = customerModelRes.data.bankInfo.company
        }
        if (customerModelRes.data.bankInfo.self) {
          customerForm.bankInfo.self = customerModelRes.data.bankInfo.self
        }
      }
      this.$set(this, 'customerForm', customerForm)
      this.editUuid = data.uuid
      this.formMode = 'edit'
      this.setCustomerShow = true
      
    },
    // 积分日志
    showScoreLogs(data, type) {
      this.logType = type
      this.nowLogUuid = data.uuid
      this.$set(this, 'logData', [])
      this.$set(this, 'logPagination', {
        current: 1,
        pageSize: 10,
        total: 0,
        filters: null,
        sorter: null
      })
      this.logDataShow = true
      this.getLogsPage()
    },
    handleLogTableChange (pagination) {
      const pager = { ...this.logPagination }
      pager.current = pagination.current
      this.logPagination = pager
      this.getLogsPage()
    },
    async getLogsPage () {
      this.logLoading = true
      const listRes = await this.$axios.post(`${Customer}/TableListByScoreLogs?type=${this.logType}&uuid=${this.nowLogUuid}`, {
        results: this.logPagination.pageSize,
        page: this.logPagination.current,
        searchParams: null,
        filters: null,
        sorter: null
      }).catch(e => {
        this.logLoading = false
        return { status: false, message: e.message.indexOf('401') > -1 ? '登录超时' : e.message }
      })
      this.logLoading = false
      if (!listRes.status) {
        this.$notification.error({ message: '获取数据错误', description: listRes.message })
        return
      }
      this.$set(this, 'logData', listRes.data.rows)
      this.$set(this.logPagination, 'total', listRes.data.count)
    },
    // 报表导出
    exportScoreFlow() {
      this.exportExcelDate = []
      this.showExportExcelDateSelect = true
    },
    async exportExcel() {
      if (!this.exportExcelDate || this.exportExcelDate.length < 2) {
        this.$message.error('请选择导出月份');
        return;
      }
      const searchParams = {
        createdAt: [this.exportExcelDate[0].format('YYYY-MM-DD') + ' 00:00:00', this.exportExcelDate[1].format('YYYY-MM-DD')  + ' 23:59:59']
      }
      
      this.modalLoading = true
      const apiRes = await this.$axios.post(`${Customer}/ExportScoreFlow`, {
        results: 20000,
        page: 1,
        searchParams,
      }).catch(e => {
        return {
          status: false,
          message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
        }
      })
      this.modalLoading = false
      if (!apiRes.status) {
        this.sending = false
        this.$message.error(apiRes.message)
        return
      }
      this.showExportExcelDateSelect = false
      DownloadJS(window.atob(apiRes.data), '统计表数据.xlsx')
    }
  }
}
</script>

<style>

</style>